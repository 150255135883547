/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import { useFormik } from 'formik'
import { getUserByToken, login, verifyCode } from '../core/_requests'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import { useAuth } from '../core/Auth'

const loginSchema = Yup.object().shape({
    email: Yup.string()
        .min(3, 'Minimum 3 symbols')
        .max(50, 'Maximum 50 symbols')
        .required('Telegram Username is required'),
    password: Yup.string()
        .min(3, 'Minimum 6 symbols')
        .max(50, 'Maximum 50 symbols')
        .required('Password is required'),
})

const codeSchema = Yup.object().shape({
    code: Yup.string().length(6, '6 Digits').required('Two-factor verification code required'),
})

const initialValues = {
    email: '',
    password: '',
    code: '',
}

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {
    const [loading, setLoading] = useState(false)
    const { saveAuth, setCurrentUser } = useAuth()

    const [isTwoAuth, setIsTwoAuth] = useState(false)
    const [tempToken, setTempToken] = useState("")

    const formik = useFormik({
        initialValues,
        validationSchema: loginSchema,
        onSubmit: async (values, { setStatus, setSubmitting }) => {
            setLoading(true)
            try {
                const { data: auth } = await login(values.email, values.password)
                
                setIsTwoAuth(true)
                setTempToken(auth.token)

                setLoading(false)
            } catch (error: any) {
                saveAuth(undefined)
                if (error.response) {
                    setStatus(error.response.data)
                } else {
                    setStatus('The login details are incorrect')
                }
                setSubmitting(false)
                setLoading(false)
            }
        },
    })

    const formCode = useFormik({
        initialValues,
        validationSchema: codeSchema,
        onSubmit: async (values, { setStatus, setSubmitting }) => {
            setLoading(true)
            try {
                const { data: auth } = await verifyCode(values.code, tempToken)
                saveAuth(auth)
                const { data: user } = await getUserByToken(auth.token)
                setCurrentUser(user)
            } catch (error: any) {
                saveAuth(undefined)
                if (error.response) {
                    setStatus(error.response.data)
                } else {
                    setStatus('The login details are incorrect')
                }
                setSubmitting(false)
                setLoading(false)
            }
        },
    })

    return (
        <>
            {isTwoAuth == false && (
                <form
                    className='form w-100'
                    onSubmit={formik.handleSubmit}
                    noValidate
                    id='kt_login_signin_form'
                >
                    {/* begin::Heading */}
                    <div className='text-center mb-11'>
                        <h1 className='text-dark fw-bolder mb-3'>Sign In</h1>
                        <div className='text-gray-500 fw-semibold fs-6'>
                            with your master account
                        </div>
                    </div>
                    {/* begin::Heading */}

                    {formik.status ? (
                        <div className='mb-10 bg-light-danger p-8 rounded'>
                            <div className='text-danger font-weight-bold'>{formik.status}</div>
                        </div>
                    ) : (
                        <div className='mb-10 bg-light-primary p-8 rounded'>
                            <div className='text-info'>
                                Use <strong>telegram username</strong> and <strong>password</strong>{' '}
                                to continue. You must have it if you are a{' '}
                                <strong>master agent</strong> in our bot.
                            </div>
                        </div>
                    )}

                    {/* begin::Form group */}
                    <div className='fv-row mb-8'>
                        <label className='form-label fs-6 fw-bolder text-dark'>Username</label>
                        <input
                            {...formik.getFieldProps('email')}
                            className={clsx(
                                'form-control bg-transparent',
                                { 'is-invalid': formik.touched.email && formik.errors.email },
                                {
                                    'is-valid': formik.touched.email && !formik.errors.email,
                                }
                            )}
                            type='text'
                            name='email'
                            autoComplete='off'
                        />
                        {formik.touched.email && formik.errors.email && (
                            <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                    <span role='alert'>{formik.errors.email}</span>
                                </div>
                            </div>
                        )}
                    </div>
                    {/* end::Form group */}

                    {/* begin::Form group */}
                    <div className='fv-row mb-3'>
                        <label className='form-label fw-bolder text-dark fs-6 mb-0'>Password</label>
                        <input
                            type='password'
                            autoComplete='off'
                            {...formik.getFieldProps('password')}
                            className={clsx(
                                'form-control bg-transparent',
                                {
                                    'is-invalid': formik.touched.password && formik.errors.password,
                                },
                                {
                                    'is-valid': formik.touched.password && !formik.errors.password,
                                }
                            )}
                        />
                        {formik.touched.password && formik.errors.password && (
                            <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                    <span role='alert'>{formik.errors.password}</span>
                                </div>
                            </div>
                        )}
                    </div>
                    {/* end::Form group */}

                    {/* begin::Wrapper */}
                    <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8'></div>
                    {/* end::Wrapper */}

                    {/* begin::Action */}
                    <div className='d-grid mb-10'>
                        <button
                            type='submit'
                            id='kt_sign_in_submit'
                            className='btn btn-primary'
                            disabled={formik.isSubmitting || !formik.isValid}
                        >
                            {!loading && (
                                <span className='indicator-label'>Continue to Robbie ...</span>
                            )}
                            {loading && (
                                <span className='indicator-progress' style={{ display: 'block' }}>
                                    Please wait...
                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                            )}
                        </button>
                    </div>
                    {/* end::Action */}
                </form>
            )}

            {isTwoAuth == true && (
                <form
                    className='form w-100'
                    onSubmit={formCode.handleSubmit}
                    noValidate
                    id='kt_login_signin_form'
                >
                    {/* begin::Heading */}
                    <div className='text-center mb-11'>
                        <h1 className='text-dark fw-bolder mb-3'>Sign In</h1>
                        <div className='text-gray-500 fw-semibold fs-6'>
                            with your master account
                        </div>
                    </div>
                    {/* begin::Heading */}

                    {formCode.status ? (
                        <div className='mb-10 bg-light-danger p-8 rounded'>
                            <div className='text-danger font-weight-bold'>{formCode.status}</div>
                        </div>
                    ) : (
                        <div className='mb-10 bg-light-primary p-8 rounded'>
                            <div className='text-info'>
                                We've sent code to your telegram account. Insert 6 digits code to
                                verify that you're tring to login.
                            </div>
                        </div>
                    )}

                    {/* begin::Form group */}
                    <div className='fv-row mb-8'>
                        <label className='form-label fs-6 fw-bolder text-dark'>Code</label>
                        <input
                            {...formCode.getFieldProps('code')}
                            className={clsx(
                                'form-control bg-transparent',
                                { 'is-invalid': formCode.touched.code && formCode.errors.code },
                                {
                                    'is-valid': formCode.touched.code && !formCode.errors.code,
                                }
                            )}
                            type='text'
                            name='code'
                            autoComplete='off'
                        />
                        {formCode.touched.code && formCode.errors.code && (
                            <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                    <span role='alert'>{formCode.errors.code}</span>
                                </div>
                            </div>
                        )}
                    </div>
                    {/* end::Form group */}

                    {/* begin::Wrapper */}
                    <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8'></div>
                    {/* end::Wrapper */}

                    {/* begin::Action */}
                    <div className='d-grid mb-10'>
                        <button
                            type='submit'
                            id='kt_sign_in_submit'
                            className='btn btn-primary'
                            disabled={formik.isSubmitting || !formik.isValid}
                        >
                            {!loading && (
                                <span className='indicator-label'>Continue to Robbie ...</span>
                            )}
                            {loading && (
                                <span className='indicator-progress' style={{ display: 'block' }}>
                                    Please wait...
                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                            )}
                        </button>
                    </div>
                    {/* end::Action */}
                </form>
            )}
        </>
    )
}
