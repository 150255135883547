import { useAuth } from '../../../../app/modules/auth'
import { MenuItem } from './MenuItem'
import { useIntl } from 'react-intl'

export function MenuInner() {
    const intl = useIntl()
    const { currentUser, logout } = useAuth()

    return (
        <>
            {/* <MenuItem title={intl.formatMessage({ id: 'MENU.DASHBOARD' })} to='/dashboard' /> */}

            <MenuItem title='Sellers' to='/sellers' />
            <MenuItem title='Buyers' to='/buyers' />
            <MenuItem title='Live' to='/live' />
            <MenuItem title='Clients' to='/clients' />
            <MenuItem title='Agent Key' to='/agents' />
            <MenuItem title='Accounting' to='/accountings' />
            {['ROOT', 'MASTER', 'FIGURES', 'SHEETS', 'ALONZO'].includes(
                currentUser?.master_agent ? currentUser?.master_agent : ''
            ) && <MenuItem title='Masters' to='/masters' />}
        </>
    )
}
