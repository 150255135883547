/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { Link } from 'react-router-dom'
import { useAuth } from '../../../../app/modules/auth'

const HeaderUserMenu: FC = () => {
    const { currentUser, logout } = useAuth()
    return (
        <div
            className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-semibold py-4 fs-6 w-275px'
            data-kt-menu='true'
        >
            <div className='menu-item px-3'>
                <div className='menu-content d-flex align-items-center px-3'>
                    <div className='symbol symbol-50px me-5'>
                        <div className='symbol-label fs-2 fw-bold text-success'>
                            {currentUser?.email[0].toUpperCase()}
                        </div>
                    </div>

                    <div className='d-flex flex-column'>
                        <div className='fw-semibold d-flex align-items-center fs-7'>
                            {currentUser?.email}
                            <span className='badge badge-light-danger fw-bold fs-8 px-2 py-1 ms-2'>
                                {currentUser?.admin == true ? 'Admin' : 'Master'}
                            </span>
                        </div>
                        <a href='#' className='fw-semibold text-muted text-hover-primary fs-7'>
                            {currentUser?.master_agent}
                        </a>
                    </div>
                </div>
            </div>

            <div className='separator my-2'></div>

            <div className='menu-item px-5 my-1'>
                <Link to='/faq' className='menu-link px-5'>
                    Help / Faq
                </Link>
            </div>

            <div className='menu-item px-5'>
                <a onClick={logout} className='menu-link px-5'>
                    Sign Out
                </a>
            </div>
        </div>
    )
}

export { HeaderUserMenu }
