import { useEffect } from 'react'

import { KTSVG } from '../../../../_metronic/helpers'

const IdleModal = () => {
    useEffect(() => {
        document.body.classList.add('modal-open')
        return () => {
            document.body.classList.remove('modal-open')
        }
    }, [])

    return (
        <>
            <div
                className='modal fade show d-block'
                id='kt_modal_idle'
                role='dialog'
                tabIndex={-1}
                aria-modal='true'
            >
                {/* begin::Modal dialog */}
                <div className='modal-dialog modal-dialog-centered mw-650px'>
                    {/* begin::Modal content */}
                    <div className='modal-content'>
                        {/* begin::Modal body */}
                        <div className='modal-body scroll-y mx-5 mx-xl-15 my-7'>
                            <div className='d-flex flex-center flex-column py-10 px-10 px-lg-20'>
                                <KTSVG
                                    path='/media/icons/duotune/general/gen044.svg'
                                    className='svg-icon svg-icon-5tx svg-icon-danger mb-5'
                                />

                                <div className='text-center'>
                                    <h5 className='fw-bolder fs-1 mb-5'>
                                        There was no activity for a while so we closed the session.
                                    </h5>

                                    <div className='separator separator-dashed border-danger opacity-25 mb-5'></div>

                                    <div className='mb-9'>You may have to login again.</div>
                                </div>
                            </div>
                        </div>
                        {/* end::Modal body */}
                    </div>
                    {/* end::Modal content */}
                </div>
                {/* end::Modal dialog */}
            </div>
            {/* begin::Modal Backdrop */}
            <div className='modal-backdrop fade show'></div>
            {/* end::Modal Backdrop */}
        </>
    )
}

export { IdleModal }
