import { lazy, FC, Suspense } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import TopBarProgress from 'react-topbar-progress-indicator'

import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import { WithChildren } from '../../_metronic/helpers'

const PrivateRoutes = () => {
    const AccountingsPage = lazy(() => import('../pages/accounting/AccountingsPage'))
    const SellersPage = lazy(() => import('../pages/sellers/SellersPage'))
    const BuyersPage = lazy(() => import('../pages/buyers/BuyersPage'))
    const ClientsPage = lazy(() => import('../pages/clients/ClientsPage'))
    const LivePage = lazy(() => import('../pages/live/LivePage'))
    const MastersPage = lazy(() => import('../pages/masters/MastersPage'))
    const AgentsPage = lazy(() => import('../pages/agents/AgentsPage'))
    const FaqPage = lazy(() => import('../pages/faq/FaqsPage'))

    return (
        <Routes>
            <Route element={<MasterLayout />}>
                {/* Redirect to Dashboard after success login/registartion */}
                <Route path='auth/*' element={<Navigate to='/sellers' />} />
                {/* Pages */}

                <Route
                    path='sellers/*'
                    element={
                        <SuspensedView>
                            <SellersPage />
                        </SuspensedView>
                    }
                />

                <Route
                    path='buyers/*'
                    element={
                        <SuspensedView>
                            <BuyersPage />
                        </SuspensedView>
                    }
                />

                <Route
                    path='clients/*'
                    element={
                        <SuspensedView>
                            <ClientsPage />
                        </SuspensedView>
                    }
                />

                <Route
                    path='live/*'
                    element={
                        <SuspensedView>
                            <LivePage />
                        </SuspensedView>
                    }
                />

                <Route
                    path='accountings/*'
                    element={
                        <SuspensedView>
                            <AccountingsPage />
                        </SuspensedView>
                    }
                />

                <Route
                    path='masters/*'
                    element={
                        <SuspensedView>
                            <MastersPage />
                        </SuspensedView>
                    }
                />

                <Route
                    path='agents/*'
                    element={
                        <SuspensedView>
                            <AgentsPage />
                        </SuspensedView>
                    }
                />

                <Route
                    path='faq/*'
                    element={
                        <SuspensedView>
                            <FaqPage />
                        </SuspensedView>
                    }
                />

                {/* Page Not Found */}
                <Route path='*' element={<Navigate to='/error/404' />} />
            </Route>
        </Routes>
    )
}

const SuspensedView: FC<WithChildren> = ({ children }) => {
    const baseColor = getCSSVariableValue('--bs-primary')
    TopBarProgress.config({
        barColors: {
            '0': baseColor,
        },
        barThickness: 1,
        shadowBlur: 5,
    })
    return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export { PrivateRoutes }
