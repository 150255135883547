/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'

import { DefaultTitle } from '../header/page-title/DefaultTitle'
import { useLayout } from '../../core'

const Toolbar1 = () => {
    const { classes } = useLayout()

    return (
        <>
            <div className='toolbar py-5 py-lg-15' id='kt_toolbar'>
                {/* begin::Container */}
                <div
                    id='kt_toolbar_container'
                    className={clsx(classes.toolbarContainer.join(' '), 'd-flex flex-stack')}
                >
                    <DefaultTitle />
                </div>
                {/* end::Container */}
            </div>
        </>
    )
}

export { Toolbar1 }
