import axios from 'axios'
import { AuthModel, UserModel } from './_models'

const API_URL = process.env.REACT_APP_API_URL

export const LOGIN_URL = `${API_URL}/login`
export const VERIFY_CODE_URL = `${API_URL}/verify_code`
export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/verify_token`

// Server should return AuthModel
export function login(email: string, password: string) {
    return axios.post<AuthModel>(LOGIN_URL, {
        email,
        password,
    })
}

export function verifyCode(code: string, token: string) {
    return axios.post<AuthModel>(VERIFY_CODE_URL, {
        code, token
    })
}

export function getUserByToken(token: string) {
    return axios.post<UserModel>(GET_USER_BY_ACCESSTOKEN_URL, {
        api_token: token,
    })
}
